import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'
import Image10 from '../../assets/project10.jpg'
import Image11 from '../../assets/project11.jpg'
import Image12 from '../../assets/project12.jpg'
import Image13 from '../../assets/project13.jpg'
import Image14 from '../../assets/project14.jpg'


const data = [
    {
        id: 1,
        category: 'frontend',
        image: Image1,
        title: "CASA ARENYS (FRONTEND)",
        desc: "An exciting vacation rental located in a peaceful place near the beach in Arenys de Mar, Spain. ",
        demo: 'https://www.casa-arenys.de/',
        // github: 'https://github.com/egattor'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "CEC WEB SOLUTIONS (Frontend)",
        desc: " It offers customized web development services without using templates, ensuring a unique online presence for each client. ",
        demo: 'https://www.cec-websolutions.com/',
        // github: 'https://github.com/egattor'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "Massina (Frontend)",
        desc: "Clothing store for women in Tacna, Perú",
        demo: 'https://massinaperu.com/',
        github: 'https://github.com/egattor'
    },
    {
        id: 4,
        category: 'videos',
        image: Image4,
        title: "HomeDOK UG (Videos)",
        desc: "HomeDOK is an online medical video consulting platform in Germany.  ",
        demo: 'https://www.youtube.com/@homedokvideosprechstunde2887',
        github: 'https://github.com/egattor'
    },
    {
        id: 5,
        category: 'Data Science & AI',
        image: Image5,
        title: "Apriori Algorithm (Data Mining)",
        desc: "Write a program to generate all association rules whose support is greater than a user-supplied minimum support and whose confidence is greater than a user supplied minimum confidence using Python.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 6,
        category: 'Data Science & AI',
        image: Image6,
        title: "Decision Tree (Data Mining)",
        desc: "Implement a decision tree-based classifier to predict whether a person’s income is more than $50k or not. The project consists of multiple components that involve data preprocessing, implementing a classification model, assessing the performance of the classifier with the different stopping criteria, and analyzing some of the models that were estimated using Python.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 7,
        category: 'Data Science & AI',
        image: Image7,
        title: "Online Assignment Managment System (Database Managment)",
        desc: "Design a conceptual and relational schema for a database and implement queries in sql",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 8,
        category: 'Data Science & AI',
        image: Image8,
        title: "Implement a CNN  for clothing classification (Machine Learning)",
        desc: "Implement a LeNet-5 and VGG convolutional neural network ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 9,
        category: 'Data Science & AI',
        image: Image9,
        title: "Understanding Hurricane Evacuation Decision Consistency during a Pandemic  (High Dimension Data Analysis)",
        desc: "The dataset collects the information from a survey made in Florida from September to November 2020 that helps to understand hurricane evacuation Decision Consistency during a Pandemic. In this survey respondents were first asked whether they evacuated during hurricane Irma or not and also asked what their evacuation decision for a hypothetical hurricane in presence of a pandemic will be. As no hurricanes hit Florida during the year 2020, the survey considered a hypothetical hurricane to understand decision consistency using R.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 10,
        category: 'Data Science & AI',
        image: Image10,
        title: "Professor’s Salary Prediction (Data Analysis)",
        desc: "This project employs multiple linear regression models to analyze the factors influencing the salaries of professors at a U.S. college, examining disparities between disciplines and genders. ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },

    {
        id: 11,
        category: 'Data Science & AI',
        image: Image11,
        title: "Multidimensional Analysis of the Factors Impacting Well-Being (Intro to Data Science)",
        desc: "This project explores the elements influencing happiness and prosperity. Utilizing the World Happiness Report and the Legatum Prosperity Index, this analysis encompasses factors like GDP per capita, life expectancy, social support, and freedom, among others. Although happiness is subjective, these datasets provide a reliable measure of well-being to aid countries in enhancing their citizens' quality of life. ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 12,
        category: 'Data Science & AI',
        image: Image12,
        title: "Implement a vowel detector (Neural Networks)",
        desc: "This project involves developing a vowel detector for 'A' and 'E' using the Perceptron and Adaline algorithms, which are binary classifiers in supervised learning. These algorithms help neurons learn and process inputs sequentially from the training set. The project focuses on implementing a single-layer model to distinguish these vowels. ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    }
    ,
    {
        id: 13,
        category: 'Data Science & AI',
        image: Image13,
        title: "ECG Feature Classification Using Multilayer Perceptron with Backpropagation and Momentum (Neural Networks)",
        desc: "This project focuses on using a Multilayer Perceptron (MLP) with one hidden layer, enhanced by backpropagation and momentum techniques, to classify Electrocardiogram (ECG) feature vectors into \"Healthy\" or \"Myocardial Infarction\" categories. The neural network architecture includes an input layer, a hidden layer, and an output layer. ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },

    {
        id: 14,
        category: 'Data Science & AI',
        image: Image14,
        title: "SEIZURES PREDICTION FOR EPILEPSY PATIENTS (Thesis)",
        desc: "This thesis advances seizure prediction by harnessing breakthroughs in EEG data and contemporary research, aiming to enhance predictive accuracy and minimize false alarms. It innovatively standardizes evaluation protocols and optimizes seizure prediction intervals, ensuring precision in early detection and maximizing patient safety through sophisticated data analysis and algorithmic strategies.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    }
    ]
//
//     {
//         id: 5,
//         category: 'uiux',
//         image: Image5,
//         title: "Project Title Five (UIUX)",
//         desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
//         demo: 'http://egatortutorials.com',
//         github: 'https://github.com/egattor'
//     },
//     {
//         id: 6,
//         category: 'frontend',
//         image: Image6,
//         title: "Project Title Six (Frontend)",
//         desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
//         demo: 'http://egatortutorials.com',
//         github: 'https://github.com/egattor'
//     },
//     {
//         id: 7,
//         category: 'frontend',
//         image: Image7,
//         title: "Project Title Seven (Frontend)",
//         desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
//         demo: 'http://egatortutorials.com',
//         github: 'https://github.com/egattor'
//     },
//     {
//         id: 8,
//         category: 'backend',
//         image: Image8,
//         title: "Project Title Eight (Backend)",
//         desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
//         demo: 'http://egatortutorials.com',
//         github: 'https://github.com/egattor'
//     },
//     {
//         id: 9,
//         category: 'frontend',
//         image: Image9,
//         title: "Project Title Nine (Frontend)",
//         desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
//         demo: 'http://egatortutorials.com',
//         github: 'https://github.com/egattor'
//     }
// ]


export default data