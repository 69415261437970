
import './portfolio.css'
import CertificationsCategories from "./CertificationsCategories";
import Certifications from "./Certifications";
import data from './data'
import {useState} from "react";
const CertificationPortfolio = () => {
    const [certifications, setCertifications] = useState(data);


    const categories = data.map(item=> item.category);
    const uniqueCategories = ["all", ...new Set(categories)];

    const filterCertificationsHandler =(category) => {
        if(category==="all")
        {
            setCertifications(data);
            return;
        }
        const filterProjects = data.filter(certification => certification.category===category);
        setCertifications(filterProjects);
    }

    return (
        <section id="certificationPortfolio">
            <h2>Last Certifications</h2>
            <p>
                Check out some of the last certifications I recently completed.
            </p>
            <div className="container portfolio__container">
                <CertificationsCategories categories={uniqueCategories} onFilterCertifications={filterCertificationsHandler}/>
                <Certifications certifications={certifications}/>
            </div>
        </section>
    )
}
export default CertificationPortfolio
