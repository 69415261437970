import Image1 from '../../assets/certification1.jpg'
import Image2 from '../../assets/certification2.jpg'
import Image3 from '../../assets/certification3.jpg'
import Image4 from '../../assets/certification4.jpg'
const data = [
    {
        id: 1,
        category: 'Data Science',
        image: Image1,
        title: "Carnegie Mellow University",
        desc: "Natural language Processing ",
        demo: 'https://certificates.emeritus.org/9ad2a49a-25b7-4fc5-98f0-d07676a776e4#gs.8bq7kb',
        // github: 'https://github.com/egattor'
    },
    {
        id: 2,
        category: 'Data Science',
        image: Image2,
        title: "Cloud Academy",
        desc: "AWS Cloud Practitioner Certification Preparation",
        demo: 'https://certificates.cloudacademy.com/3a0bc87e57735bc470a278944f71b8dd7e480569.pdf',
        // github: 'https://github.com/egattor'
    },
    {
        id: 3,
        category: 'Cloud',
        image: Image3,
        title: "Amazon Web Services ",
        desc: "AWS CLOUD PRACTITIONER CERTIFICATION ",
        demo: 'https://massinaperu.com/',
        github: 'https://github.com/egattor'
    },
    {
        id: 4,
        category: 'Data Science',
        image: Image4,
        title: "Peruvian University of Applied Sciences (UPC)",
        desc: "CHATGPT AND PROMPT ENGINEERING ",
        demo: 'https://massinaperu.com/',
        github: 'https://github.com/egattor'
    }

]

export default data