import { AiOutlineLinkedin } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiFillGithub } from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/carlos-escudero-castillo/', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://www.youtube.com/channel/UCJZMd2-AC0jZYuVb10cZFzg', icon: <AiOutlineYoutube />},
    {id: 3, link: 'https://www.youtube.com/channel/UCrfSZsAYzeeyZXmBbZSnIKg', icon: <AiOutlineYoutube />},
    {id: 4, link: 'https://github.com/scudr', icon: <AiFillGithub/>}
]

export default data