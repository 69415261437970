import './about.css'
import CV from '../../assets/CV.pdf'
import AboutImage from '../../assets/about.jpg'

import {HiDownload}  from "react-icons/hi";
import Card from '../../components/Card'
import data from './data'

const About = () => {
    return (
        <section id="about" data-aos="fade-in">

            <div className="container about__container">
                <div className="about__left">
                    <div className="about__portrait">
                        <img src={AboutImage} alt="About Image"/>
                    </div>

                </div>
                <div className="about__right">
                    <h2>About Me</h2>
                    <div className="about__cards">
                        {
                            data.map(item=> (
                                <Card key={item.id} className='about__card'>
                                    <span className='about__card-icon'>{item.icon}</span>
                                    <h5>{item.title}</h5>
                                    <small>{item.desc}</small>
                                </Card>
                            ))
                        }
                    </div>
                    <p>
                        <span className="highlight">Software Developer with a Master's Degree in Data Science & AI</span>

                    </p>
                    <p>
                        I am an experienced <span className="highlight">software developer</span> with over 8 years of practical experience in various leading <span className="highlight">IT companies in Germany</span>, specializing in enterprise web applications and including an internship in Data Science in the USA.  My versatile skill set extends beyond coding, including strategic contributions to design, architecture, and cross-functional project leadership. With a <span className="highlight">Master’s Degree in Data Science & AI</span>, focused on <span className="highlight">Deep Learning and NLP</span>, I thrive at the intersection of systematic software development and the transformative power of AI technologies. My aspiration is to contribute to and lead projects where the development of AI models goes hand-in-hand with crafting comprehensive software ecosystems. Driven by a passion for continuous learning and a dedication to personal and professional growth, I seek to make impactful contributions to innovative projects.
                    </p>
                    <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>

                </div>
            </div>
        </section>
    )
}
export default About
