
import Card from "../../components/Card";

const Certification = ({certification}) => {
    console.log("certificationnnnnnnnn");
    console.log(certification);
    return (
        <Card className="portfolio__project">
            <div className="portfolio__project-image">
                <img src={certification.image} alt="Portfolio Project Image" />
            </div>
            <h4>{certification.title}</h4>
            <p>{certification.desc}</p>
            <div className="portfolio__project-cta">
                <a href={certification.demo} className="btn sm primary" target="_blank" rel="noopner noreferrer">Demo</a>
                {/*<a href={project.github} className="btn sm primary" target="_blank" rel="noopner noreferrer">Gitbub</a>*/}
            </div>
        </Card>
    )
}
export default Certification
