import { FaAward } from 'react-icons/fa';
import { TbBooks } from 'react-icons/tb';
import { BiHappyHeartEyes } from 'react-icons/bi';
import { GrSystem } from "react-icons/gr";
import { TbDeviceAnalytics } from "react-icons/tb";

const data = [
    {id: 1, icon: <FaAward />, title: 'Experience', desc: '8+ Years Working'},
    {id: 2, icon: <GrSystem  />, title: 'Software Engineering', desc: 'Experience in german companies'},
    {id: 3, icon: <TbDeviceAnalytics  />, title: 'Data Science', desc: 'Master in Data Science & AI at Florida International University'},
    // {id: 3, icon: <BiHappyHeartEyes />, title: 'Clients', desc: '70+ happy clients'}
];

export default data;