import HeaderImage from '../../assets/header.jpg'
import './header.css'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {useEffect} from "react";

const Header = () => {

    useEffect(() => {
        AOS.init({duration:2000})
    }, []);
    
    return (
        <header id='header'>
        <div className="container header__container">
            <div className="header__profile" data-aos="fade-in">
                <img src={HeaderImage} alt="Carlos Junior Escudero Castillo"/>
            </div>
            <h3 data-aos="fade-up">Carlos Junior Escudero Castillo</h3>
            <p data-aos="fade-up">
                I'm an experienced <span className="highlight">software developer</span> with expertise in enterprise web applications and <span className="highlight">Data Science</span>, holding a <span className="highlight">Master’s in Data Science & AI </span>. Specializing in integrating AI technologies with software development.
            </p>

            <div className="header__cta" data-aos="fade-up">
                <a href="#contact" className='btn primary'>Let's Talk</a>
                <a href="#portfolio" className='btn light'>My Work</a>

            </div>

            <div className="header__socials">
                {data.map(item=><a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer" >
                    {item.icon}
                </a>)}
            </div>
        </div>
        </header>
    )
}
export default Header
