import React from 'react'
import Certification from "./Certification";

const Certifications = ({certifications}) => {
    console.log(certifications);
    console.log('certificationssssssssssssss');
    return (
        <div className="portfolio__projects" data-aos="fade-up">
            {
                certifications.map(certification => (
                    <Certification key={certification.id} certification={certification} />
                ))
            }
        </div>
    )
}
export default Certifications
