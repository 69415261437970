import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'


import { TbDeviceAnalytics } from "react-icons/tb";
import { SiAmazonaws } from "react-icons/si";
import { HiLanguage } from "react-icons/hi2";

const data = [
    {
        id: 1,
        icon: <TbDeviceAnalytics />,
        title: "Data Science & Analytics",
        desc: "Leverage the power of data with advanced analytics techniques. I provide data-driven insights that help optimize processes, enhance decision-making, and boost business outcomes."
    },
    {
        id: 2,
        icon: <RiReactjsLine/>,
        title: "AI & Machine Learning Solutions",
        desc: "Integrate cutting-edge AI and machine learning technologies into your projects. From predictive modeling to AI-driven automation, enhance your operations and create smart, adaptive systems."
    },
    {
        id: 3,
        icon: <FaServer/>,
        title: "Frontend Development",
        desc: "Build beautiful, responsive, and user-friendly websites. I ensure your web presence is modern, accessible, and perfectly aligns with your brand identity, delivering an excellent user experience on all devices."
    },
    {
        id: 4,
        icon: <AiFillAppstore/>,
        title: "Backend Development",
        desc: "Develop robust backend systems that are secure and scalable. I focus on creating efficient, reliable backend architectures that support your business needs and ensure smooth operations."
    },
    {
        id: 5,
        icon: <SiAmazonaws />,
        title: "Cloud Solutions & DevOps",
        desc: "Streamline your IT infrastructure with cloud solutions and DevOps practices. From setup to management, optimize your workflows, increase deployment efficiency, and reduce operational costs with tailored cloud strategies."
    },
    {
        id: 6,
        icon: <HiLanguage />,
        title: "Large Language Model Integration",
        desc: "Harness the power of large language models like ChatGPT in your applications. I specialize in prompt engineering and integrating conversational AI to enhance user interaction and automate responses."
    }
];
export default data